<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import { VxTable, VxCellPlayerAvatar, VxCellLink, VxCellDate } from '@/components/table'
import { passDataToResource, welcomeOffers, welcomeOfferUsedStatuses } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'
import { bonusUrls } from '@/views/bonuses/bonusesService'

export default {
  name: 'WelcomeOffersUsed',
  components: { VxTable },
  setup () {
    const resource = passDataToResource(welcomeOffers.getAllUsed, {
      requestParams: {
        params: { include: 'welcomeOffer,bonus,player' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'welcome_offer',
        component: VxCellLink,
        tdAttr: ({ id, title }) => ({
          label: title,
          subLabel: `#${id}`,
          href: buildRoute(path.welcomeOffers, { query: { id } })
        })
      },
      {
        key: 'bonus',
        component: VxCellLink,
        tdAttr: ({ id, name, bonus_type }) => ({
          label: name,
          subLabel: `#${id}`,
          href: buildRoute(bonusUrls[bonus_type], { query: { id } })
        })
      },
      {
        key: 'status',
        sortable: true
      },
      {
        key: 'created_at',
        component: VxCellDate,
        sortable: true
      },
      {
        key: 'updated_at',
        component: VxCellDate,
        sortable: true
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_name',
        type: filterTypes.text
      },
      {
        key: 'welcome_offer_title',
        type: filterTypes.text
      },
      {
        key: 'money_bonus_name',
        label: 'Bonus title',
        type: filterTypes.text
      },
      {
        key: 'status',
        type: filterTypes.select,
        options: Object.values(welcomeOfferUsedStatuses)
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['created_from', 'created_to']
      },
      {
        key: 'updated_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['updated_from', 'updated_to']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
